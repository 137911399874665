<template>
    <v-heading :size="4" v-bind="attributes" class="bg-pattern-charlie-brown filter">
        <slot />
    </v-heading>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {
        attributes() {
            return {
                ...this.$attrs,
            };
        },
    },
    mounted() {
        this.$forceUpdate();
    },
};
</script>
